import * as React from "react"
import Layout from "../../components/layout"
import "@fontsource/raleway/300.css"
import Hero from '../../components/hero'
import { Link } from 'gatsby'
import Header from '../../components/header'
import CharacterGrid from '../../components/charactergrid'
import InBrief from '../../components/inbrief'
import AdBanner from '../../components/adbanner'
import { StaticImage } from 'gatsby-plugin-image'
import { ImageRight, ImageFull, ImageCenter } from '../../components/imageelements'
import { SynopsisAct, SynopsisScene } from '../../components/synposisstyling'
import Bibliography from '../../components/bibliography.js'
import { Helmet } from "react-helmet"
import AudioSet from '../../components/audioset'
import { AdContainer } from '../../components/adstyling'
import { FunFactsHeader } from '../../components/funfactsstyling'
import { MainBodySection } from '../../components/generalstyling'
import VideoPlayer from '../../components/videoplayer'
import BlockQuote from '../../components/blockquote'

const characters = [
  {name: 'Peter Grimes', voice: 'Tenor', desc: 'The title role. A mean, violent fisherman ostracised from the community. The anti-hero of the piece.'},
  {name: 'Ellen Orford', voice: 'Soprano', desc: 'A middle-aged spinster with a soft spot for Grimes. Also, something of a pariah in the community.'},
  {name: 'Auntie', voice: 'Contralto', desc: 'The manager of The Boar pub.'},
  {name: 'Nieces 1 and 2', voice: 'Sopranos', desc: 'The nieces of Auntie. The best looking girls in town and as a result a major attraction of The Boar for the male population.'},
  {name: 'Balstrode', voice: 'Baritone', desc: 'A retired merchant skipper who stands apart from the community in attempting to understand and help Grimes.'},
  {name: 'Mrs. (Nabob) Sedley', voice: 'Mezzo-soprano', desc: 'A sad, lonely, older woman who gossips endlessly in a rather unpleasant fashion. Also addicted to Laudanum (mild opium).'},
  {name: 'Swallow', voice: 'Bass', desc: 'A lawyer and town magistrate, and as a result one of the most powerful people in the town. He\'s also a bit of a bully. His major appearances bookend the opera.'},
  {name: 'Ned Keene', voice: 'Baritone', desc: 'The town apothecary (basically a medical quack). One of the more sympathetic townsfolk. Ned finds a new assistant for Grimes when no one else will help.'},
  {name: 'Bob Boles', voice: 'Tenor', desc: 'A methodist fisherman with a taste for alcohol and women. One of the least savoury characters in the town.'},
  {name: 'Rev. Horace Adams', voice: 'Tenor', desc: 'The town rector. Mostly harmless.'},
  {name: 'Hobson', voice: 'Bass', desc: 'The town carrier. Refuses to get John until Ellen convinces him otherwise.'},
  {name: 'John', voice: 'Silent', desc: 'Grimes\'s new apprentice. Inevitably doomed.'},
];

const inbrief = {name: 'Peter Grimes', composer: "Benjamin Britten", librettist: "Montagu Slater", language: "English", date: 'June 7th, 1945', acts: "Three", musiclength: "Two hours, twenty five minutes"};

const bibliography = [
  {name: 'Peter Grimes (Cambridge Opera Handbooks)', writer: 'Philip Brett', url: 'https://amzn.to/2WxNmiZ'},
  {name: 'The Operas of Benjamin Britten: Expression and Evasion', writer: 'Claire Seymour', url: 'https://amzn.to/2WipAHk'},
  {name: 'The Music of Benjamin Britten', writer: 'Peter Evans', url: 'https://amzn.to/3yec6tv'},
  {name: 'Benjamin Britten', writer: 'Michael Oliver', url: 'https://amzn.to/3ki4tgF'},
  {name: 'Benjamin Britten: A Life for Music', writer: 'Neil Powell', url: 'https://amzn.to/3879eUF'},
];

const BrittenQuote = {content: 'A subject very close to my heart—the struggle of the individual against the masses. The more vicious the society, the more vicious the individual.', quotee: 'Benjamin Britten'}

// markup
const GrimesPage = () => {
  return (
    <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Peter Grimes | The Opera 101</title>
          <html lang="en-us" />
          <link rel="canonical" href="https://theopera101.com/operas/grimes" />
          <meta name="description" content="A guide to Benjamin Britten's 20th Century masterpiece, Peter Grimes. Including Synopsis, Music & Arias, Fun Facts, Running Time and much more!" />
        </Helmet>

        <Hero title="Peter Grimes" subtitle="An Opera by Benjamin Britten"/>
        <MainBodySection>

          <BlockQuote quote={BrittenQuote}/>

          <ImageFull>
            <StaticImage src="../../images/operas/grimes/GAlone_CliveBarda_ROH.jpg" alt="Ben Heppner as Peter Grimes"/>
            <figcaption>Ben Heppner as Grimes &copy; Clive Barda/ROH</figcaption>
          </ImageFull>

          <p><i>Peter Grimes</i> is a big opera with a very tight focus. There are huge choral scenes and many supporting players, but the work holds fast to Grimes himself. Grimes is the ultimate outsider, one whom Britten associated with strongly. He’s far from a hero, not even close, but he’s no pantomime villain either. “Now the
              Great Bear”, his startling Act II aria, reveals the wounded man beneath the menacing facade. This ambiguity runs throughout the piece, Grimes’s nastiness set against the overwhelming mob-like behaviour of the townsfolk.</p>

          
          <div id="ezoic-pub-ad-placeholder-102"> </div>

          <p>Another major element is that of nature, the sea ever-present in the drama and no more so than in the incredible interludes (music that is frequently heard in concert halls as the “4 Sea Interludes”). The town is dependent on the sea, fishing is how they make their living, but it is a dangerous bargain: they live by the sea and frequently die by it too, as we see with Grimes’s successive apprentices.</p>

          <p>Ellen Orford provides a counterpoint to the harshness of the environment and town, a schoolmistress spinster who cares for Grimes. He too wishes to marry her if he can make money, but it is essentially a social contract, not a
              loving one (though as with everything in this opera, there is plenty of ambiguity).</p>

          <p><em>Peter Grimes</em> is a tragedy from start to finish, but it's also a masterpiece of musical theatre. The sheer force of the music lifting the narrative to realms rarely reached in 20th Century opera.</p>

          <AudioSet audiosrc="/audio/grimes/SundayMorning.mp3" desc="The opening of Act II - Sunday Morning"/>

          <Header title="In Brief"/>

          <InBrief content={inbrief}/>

          </MainBodySection>

  
          <div id="ezoic-pub-ad-placeholder-103"> </div>


            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="Characters"/>

            <CharacterGrid rows={characters}/>

            <Header title="Synopsis"/>

            <SynopsisAct>Prologue and Act I - Running Time: 55 mins</SynopsisAct>

            <SynopsisScene>Prologue</SynopsisScene>

            <p>Peter Grimes’s apprentice has died at sea. At the town court, the coroner questions him about his actions.
                His answers are far from satisfactory, but the court concludes that the death was accidental. The crowd of townspeople disagree, braying for his punishment as Grimes rails against them.
                Only Ellen Orford comforts him against the force of the crowd.</p>

            <ImageFull>
              <StaticImage src="../../images/operas/grimes/grimesinquest_Zurich.jpg" alt="Grimes on trial, Zurich Opera"/>
              <figcaption>Grimes on trial, Zurich Opera</figcaption>
            </ImageFull>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-104"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <SynopsisAct>Act I</SynopsisAct>

            <SynopsisScene>Scene 1</SynopsisScene>

            <p>The townsfolk sing of their daily grind with and against the sea. We are outside the town pub, operated by Auntie and her two nieces, where various local figures congregate. Grimes enters needing a new apprentice. Ned Keene, the apothecary, has found him one from the workhouse, but Grimes needs someone to
                collect the boy for him.</p>

            <ImageFull>
              <StaticImage src="../../images/operas/grimes/GrimesBar_ROH_CliveBarda.jpg" alt="Grimes arrives at the pub, Royal Opera"/>
              <figcaption>Grimes arrives at the pub, Royal Opera</figcaption>
            </ImageFull>

            <p>None are willing. Indeed they are actively resistant to Grimes having another boy. Eventually, Ellen enters and offers to get the boy. She defends Grimes, “Let her among you without fault, Cast the first stone” (though it’s difficult to argue Grimes hasn’t thrown one helluva lot of stones himself). She
                leaves to get the boy.</p>

            <p>A storm approaches. Most head inside, many to the pub, to batten down the hatches for the evening.
                Captain Balstrode stays with Grimes, warning him of the risk of yet more tragedy for a new apprentice.
                Grimes retorts that he needs a boy to earn money, earn the Borough's respect, and open the door for his marriage to Ellen. Balstrode thinks Grimes foolish, telling him to marry Ellen at once: but
                it is all for nought.</p>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-105"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <SynopsisScene>Scene 2</SynopsisScene>

            <p>Later that night, the storm is raging, and we are inside the Boar where much of the community is holed up.
                Balstrode attempts to calm the place, particularly the drunk Bob Boles, who is trying to have his way with one of Auntie’s nieces. Keene comes dashing in from the cold with the news that part of the cliff has
                fallen into the sea near Grimes’s hut.</p>

            <p>Grimes enters in a blaze. </p>

            <p>Chorus:<br/>
                <b>Talk of the devil and there he is<br/>
                    A devil he is, and a devil he is.<br/>
                    Grimes is waiting his apprentice.</b></p>

            <p>Grimes responds to the hostility by retreating into himself and singing the soaring reverie, “Now the great
                Bear and Pleiades”...</p>
          
            <VideoPlayer src="https://www.youtube.com/embed/7vaKnU8nSIM?start=6&autoplay=1" id="7vaKnU8nSIM" title="Stuart Skelton sings 'Now the Great Bear and Pleiades'"/>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-106"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>            

            <p>Boles attacks Grimes, but Balstrode holds him down. To restore the peace, Balstrode suggests a song,
                the lilting round “Old Joe has gone fishing”.</p>

            <VideoPlayer src="https://www.youtube.com/embed/G28abbUw4g8?autoplay=1" id="G28abbUw4g8" title="Old Joe Has Gone Fishing"/>

            <p>Ellen enters with the boy. The pair are freezing, but Grimes immediately drags the boy away. The crowd are incensed as the curtain falls.</p>

            <SynopsisAct>Act II - Running Time: 50 mins</SynopsisAct>

            <SynopsisScene>Scene 1</SynopsisScene>

            <ImageRight>
              <StaticImage src="../../images/operas/grimes/auntie_KenHoward_SanDiego.jpg" alt="Grimes is judged, San Diego Opera"/>
              <figcaption>Grimes is judged, San Diego Opera</figcaption>
            </ImageRight>

            <p>We are back outside the pub. It’s a Sunday morning a few weeks after the last scene. Most of the town is at church, but Ellen sits with John, Grimes’s apprentice. She longs to hear from him about his new life, but John remains steadfastly silent. That longing turns to shock when she finds a nasty bruise on his
                neck.</p>

            <p>Grimes enters, and Ellen confronts him, but he dismisses it as an accident. In anger, Grimes strikes Ellen and takes off with the boy to go fishing. The town is beginning to leave church, and some of the community oversee Grimes's actions. They are a gossipy judgemental lot, but for all that, they are loath to
                act themselves: “Grimes is at his exercise”.</p>

            <AudioSet audiosrc="/audio/grimes/grimesExercise.mp3" desc="Grimes is at his exercise"/>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-107"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <p>Their collective anger grows, however, and eventually, they make off like a pitchfork-wielding mob, led by Swallow, to Grimes’s hut. Ellen, Auntie and her nieces remain singing despondently of how women
                have to deal with men.</p>

            <ImageFull>
              <StaticImage src="../../images/operas/grimes/Crowd_MET_KenHoward.jpg" alt="A mob assembles, Metropolitan Opera"/>
              <figcaption>A mob assembles, Metropolitan Opera</figcaption>
            </ImageFull>

            <SynopsisScene>Scene 2</SynopsisScene>

            <p>Grimes has returned to his home with John and is fuming. He drives John to prepare for the fishing trip
                but loses himself in memories of his last apprentice and his slow realisation that his dreams of
                happiness are never to be.</p>

            <p>The approaching mob snaps Grimes back to reality, but he is now paranoid and even more angry than before.
                They quickly ready for sea, and Grimes orders John to climb down the cliff to the boat carefully. Alas,
                the boy slips and falls, crashing to his death.</p>

            <p>Grimes follows anyway, and when the mob arrives, they find the hut empty. With nothing to be doing, the mob disperses into the night.</p>

            <ImageFull>
              <StaticImage src="../../images/operas/grimes/BoyGrimes_OperaAustralia.jpg" alt="Grimes (Stuart Skelton) prepares his apprentice for sea, Opera Australia"/>
              <figcaption>Grimes (Stuart Skelton) prepares his apprentice for sea, Opera Australia</figcaption>
            </ImageFull>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-108"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <SynopsisAct>Act III - Running Time: 40 mins</SynopsisAct>

            <SynopsisScene>Scene 1</SynopsisScene>

            <ImageRight maxwidth="240px">
              <StaticImage src="../../images/operas/grimes/Ellen_Aldeburgh_RobertWorkman.jpg" alt="Ellen Orford (Giselle Allen), Aldeburgh Festival"/>
              <figcaption>Ellen Orford (Giselle Allen), Aldeburgh Festival</figcaption>
            </ImageRight>

            <p>A few nights later, a dance is in full swing at the Moot Hall. Mrs Sedley aggressively tries to convince Ned Keene that Grimes has killed his new apprentice. She hides as Ellen and Balstrode discuss Grimes’s return from the sea. He had been away several nights (since we last saw him, in fact), and disturbingly Balstrode has found a boy’s sweater washed up on the beach. Ellen realises it is the sweater that she
                herself knitted for John...</p>

            <p>Mrs Sedley, overhearing all this, convinces Swallow of Grimes’s guilt and with Hobson another
                bloodthirsty mob is amassed to hunt down Grimes.</p>

            <SynopsisScene>Scene 2</SynopsisScene>

            <p>Grimes has gone quite mad, wandering the beach, mentally reliving his past (this is something of a Bel
                Canto style mad scene). Ellen and Balstrode find him, he barely notices their approach, and Balstrode tells him to take his boat out and sink it. Ellen says nothing as he does so, departing to the sea for one last time.</p>

            <p>The following day, the Borough reawakens. The coast guard reports a ship sinking off the coast. It is
                quickly put aside by Auntie as “one of these rumours”.</p>

          </MainBodySection>

            <div id="ezoic-pub-ad-placeholder-109"> </div>
  
            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="Where in the World"/>

            <p><i>Peter Grimes</i> takes place in a supposedly generalised “The Borough, a small fishing town on the East Coast
                    [of England]”, but in reality, it is set specifically in Aldeburgh, a real town that Britten grew up near and later lived by. Aldeburgh is located in Suffolk on a gravelly patch of land between the River Alde and the North Sea. It’s a much less bleak place than Crabbe and Britten paint it, though you wouldn’t want to be caught outside by the sea on a cold, wet night.</p>

            <ImageFull>
              <StaticImage src="../../images/operas/grimes/aldeburghBeach.jpg" alt="The Beach at Aldeburgh"/>
              <figcaption>The Beach at Aldeburgh</figcaption>
            </ImageFull>

            <Header title="History"/>

            <p>Britten came across George Crabbe’s work in 1941 while still living in America. He and Pears were staying
            in Escondido, a city in California, when they happened upon an article about Crabbe in the “Listener” by E. M.
            Forster. They seized upon Crabbe’s works, reading them extensively as they made their way back across the
            States, but at this stage, Britten wasn’t seriously considering taking on a piece on the scale of <i>Peter
            Grimes</i>.</p>

            <p>It might never have been were it not for a $1000 commission from the Koussevitzky Music Foundation. Serge
                Koussevitzky was a Russian born conductor with a passion for new music. Britten and Pears were in Boston to hear him conduct Britten’s “Sinfonia da Requiem” at the start of 1942 when he offered the commission (the piece would eventually be dedicated to the memory of Natalie Koussevitzky, Serge’s wife).</p>

            <p>On the long boat journey back to England, which took more than four weeks, Britten wrote <i>A Ceremony of Carols</i>
                amongst other pieces whilst Pears began working on a possible scenario for Grimes. Their first choice for a
                librettist, Christopher Isherwood, wasn’t interested (and in any case, Isherwood was based in the US, so it wasn’t hugely practical with Britten and Pears’s return to the UK). Montagu Slater was settled upon, a
                poet/playwright/filmmaker Britten had worked with in the past.</p>

            <p>Grimes progressed as Pears returned to singing by joining the Sadler’s Wells Opera in early 1943. The Sadler’s
                Wells Opera Company had survived the war by becoming an itinerant company touring the UK led by the soprano Joan
                Cross. With the war coming to an end, the company was to return to their home at Sadler’s Wells in Rosebery
                Avenue and Cross set upon the idea of reopening with the world premiere of <i>Peter Grimes</i>.</p>

            <p>It wasn’t plain sailing by any means! Covent Garden wanted the opera too, and many in the Sadler’s Well’s Company disliked the music, the plot and the fact that after years of being unable to perform the big grand operas, they were going to be denied the possibility of doing so for yet longer.</p>

            <p>It all came together in the end with Pears as Grimes and Cross singing Ellen Orford. To wild acclaim, <i>Peter Grimes</i> premiered at Sadler’s Wells on the 7th of June 1945. It was a night that would change British Opera forever and launch Britten into a glittering international career.</p>

            <ImageFull>
              <StaticImage src="../../images/operas/grimes/Grimes_BBC_1969.jpg" alt="Britten and Pears preparing the BBC film of Peter Grimes"/>
              <figcaption>John Culshaw, Benjamin Britten and Peter Pears preparing for the BBC film of Peter Grimes in
                    1969 &copy; BBC</figcaption>
            </ImageFull>

          </MainBodySection>

            <div id="ezoic-pub-ad-placeholder-110"> </div>
  
            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="Fun Facts"/>

            <FunFactsHeader>Site-specific!</FunFactsHeader>

            <p>For Britten’s Centenary in 2013, the Aldeburgh Festival, the festival Britten himself founded, staged a production of Grimes on the Beach at Aldeburgh. Site-specific opera is always to be cherished but it was a rare and wonderful thing for Britten’s sea-inspired music to be accompanied by the sounds of the sea!</p>

            <ImageFull>
              <StaticImage src="../../images/operas/grimes/littlegob.jpg" alt="Grimes on the Beach"/>
              <figcaption>Grimes on the Beach, &copy; Robert Workman</figcaption>
            </ImageFull>

            <FunFactsHeader>Librettists...</FunFactsHeader>

            <p>The libretto for Peter Grimes was fashioned out of George Crabbe’s “The Borough” by Montagu Slater. Slater was no great poet, he was better known for left-wing political journalism, but in some regards, this worked well for Britten and Pears. Though Slater was generally resistant to having his work altered, his background meant that he was no poetic prophet coming from the high mountains to deliver this perfect work: he was prepared to make musically needed changes as the opera developed.
                The creative team tampered endlessly with the text. It went through many revisions, becoming something quite different from Crabbe’s original poem. However, Slater’s true feelings about all this tampering are perhaps revealed in his decision, much to Britten’s irritation, to publish his original, unaltered libretto in a collection of his works.</p>

          </MainBodySection>

            <div id="ezoic-pub-ad-placeholder-111"> </div>
  
            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <FunFactsHeader>War</FunFactsHeader>

            <p>Britten empathised with Grimes’s isolation for several reasons, but one of the most prominent was perhaps Britten’s status as a conscientious objector during WWII. He spent the first part of the war in the USA away from the conflict and then applied for official conscientious objector status upon his return to Britain. However, Britten did not receive nearly as much abuse as his friends W. H. Auden and Charles Isherwood (who came in for considerable criticism in the press), though he was still in a relatively exposed position. Early in the war, the BBC had gone as far as to place a ban on the employment of conscientious objectors, luckily abandoned by the time of Britten and Pears’s return, and the Royal Philharmonic Society refused to premiere Britten’s Violin Concerto citing his lack of popularity.</p>

            <p>It was against this background that Grimes was written and premiered, a tough time for the tiny minority who didn’t overtly support the war effort. Britten would return more overtly to pacifist themes later in his life culminating in his great <i>War Requiem</i> for the rededication of Coventry Cathedral.</p>

            <FunFactsHeader>Popular?</FunFactsHeader>

            <p><i>Peter Grimes</i> is by almost every measure one of the most popular 20th Century works in the operatic repertory. It was a hit from the start, quickly performed across the globe with 16 productions in 7 different languages within three years of its premiere. <i>Grimes</i> has maintained a fairly consistent hold ever since, <a href="http://www.operabase.com/top.cgi?lang=en&break=0&show=opera&no=100&nat=">ranking 88th on the most performed operas between 2008 and 2013</a>, making it one of the top five most performed post WWII works (Britten’s later chamber opera <Link to="/operas/screw"><i>The Turn of the Screw</i></Link> one of the few works even more popular).</p>

            <FunFactsHeader>Adults and their children</FunFactsHeader>

            <ImageCenter>
              <StaticImage src="../../images/operas/grimes/veniceboy.jpg" alt="The ENO's Death in Venice"/>
              <figcaption>Death in Venice, &copy; ENO/Hugo Glendinning</figcaption>
            </ImageCenter>

            <p><i>Peter Grimes</i> is a work fraught with potential interpretations but one interesting and often overlooked element concerns the apprentice John. He is silent, unable to communicate with the adults around him. This total failure of communication between adults and child is a theme that runs through Britten’s works and frequently leads to tragedy. Tadzio is silent in <i>Death in Venice</i>, a boy worlds apart from the elderly protagonist, whilst <i>The Turn of the Screw</i> quite literally places the desperately possessive adult ghosts in a different world from the children.</p>

            <FunFactsHeader>Jon Vickers</FunFactsHeader>

            <p>Peter Pears originated the role of Grimes, but the Canadian tenor Jon Vickers would soon become one of the world’s leading performers of it. Britten was remarkably unkeen on Vickers performance, however, for a variety of reasons. In particular, as recounted in Jeannie Williams’s, <a href="https://www.amazon.com/gp/product/1555536743/ref=as_li_ss_tl?ie=UTF8&camp=1789&creative=390957&creativeASIN=1555536743&linkCode=as2&tag=beautiful2get-20"><em>“Jon
                    Vickers: A Hero’s Life”</em></a> because Vickers took to altering the text: amongst other changes “Out of the hurly-burly” to “How should I know?” because he thought the slang too hard to follow. Vickers also saw Grimes not as a repressed homosexual but as a universal oppressed figure (which is an entirely fair approach) but, in doing so, tended to coarsen Grimes away from Britten’s more visionary figure. Whatever Britten’s opinion, Vickers would have an enormous impact on how the role came to be sung, his heavier, less sympathetic portrayal now more common than the more poetic Pears.
            </p>

            <Header title="Bibliography"/>

            <Bibliography content={bibliography}/>

        </MainBodySection>
    </Layout>
  )
}

export default GrimesPage
